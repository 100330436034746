/* eslint-disable */
export const toBase64 = (file: File): Promise<string> => {
	return new Promise<string> ((resolve,reject)=> {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			// @ts-ignore
			resolve(reader.result.toString());
		};
		reader.onerror = error => reject(error);
	})
}
