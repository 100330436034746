import $ from "jquery";

function Dropbox(e) {
	if (e.type == "mousedown")
	{
		var value = $(e.target).attr('id');
		var name = $(e.target).text();
		if (value)
		{
			if ($(e.target).parents('.js-wrap').find('.js-val').val() != value)
			{
				$(e.target).parents('.js-wrap').find('.js-name').val(name);
				$(e.target).parents('.js-wrap').find('.js-val').val(value)[0].dispatchEvent(new Event('input'));
			}
			
			$(e.target).parents('.js-wrap').removeAttr('style');
			$(e.target).parents('.js-wrap').find('.js-list').slideUp(200);
		}
	}
	else if (e.type == "focus")
	{
		$(e.target).parents('.js-zindex').css('z-index', '200');
		$(e.target).parent('.js-wrap').css('z-index', '200');
		$(e.target).parent('.js-wrap').find('.js-list').slideDown(200);
	}
	else if (e.type == "blur")
	{
		if (!$(e.target).parents('.js-list').length)
		{
			$(e.target).parent('.js-wrap').removeAttr('style');
			$(e.target).parent('.js-wrap').find('.js-list').slideUp(200);
		}
	}
}

export default Dropbox;