import {Vue} from 'vue-class-component';
import { Getter, Mutation, State, Action, namespace} from 'vuex-class'
import { ModelAccountState } from '../../../../store/modelAccount/types'
import { CustomerState } from '../../../../store/customer/types';
import {getLocalStorageService, getSessionStorageService} from '../../../../services/storage.service'
import router from "../../../../route/routing";
import * as locker from "../../../../store/locker.js"
import Dropbox from "../../../../store/dropbox";
import $ from "jquery";
import {toBase64} from "@/services/api.upload";
import * as messageModal from "@/store/message-modal.js"

const namespaceName: string = 'modelAccount';

const customer = namespace('customer');

export default class ModelAccountMyAccountPage extends Vue {
    @Action('getModelInfo', {namespace: namespaceName}) getModelInfo: any;
    @Getter('modelInfo', {namespace: namespaceName}) modelInfo: any;

    @customer.Action('fetchUserData') fetchUserData: any;
    @customer.Getter('getUserData') userData: any;

    @Action('getShopCategories', {namespace: namespaceName}) getShopCategories: any;
    @Action('getModelProducts', {namespace: namespaceName}) getModelProducts: any;
    @Getter('shopCategories', {namespace: namespaceName}) shopCategories: any;
    @Getter('modelProducts', {namespace: namespaceName}) modelProducts: any;

    @Action('updateProduct', {namespace: namespaceName}) updateProduct: any;
    @Action('deleteProduct', {namespace: namespaceName}) deleteProduct: any;

    avatar = "";

    items: any = [];

    async ImageUpload(e : any, item : any) {
        item.need_save = true;

        return await toBase64(e.target.files[0]).then(function(data)
        {
            item.image = data;
        });
    }

    Dropbox(e: any) {
        Dropbox(e);
    }

    DeleteItem(id: number, name : any, text : string) {
        var _this = this;

        if (confirm(text+" «"+name+"»‎?"))
        {
            this.deleteProduct(id).then(function () {
                _this.GetProductList();
            });
        }
    }

    SaveItem(item : any)
    {
        var _this = this;

        let data = {
            get : {
                id : item.id
            },
            post : {
                category_id : item.category_id,
                name: item.name,
                description: "",
                image: item.image,
                price: item.price,
                need_save: false
            }
        };

        this.updateProduct(data).then(function (response : any) {
            if (response.status == true) {
                item.need_save = false;
            } else {
                if (response.errors.length)
                    messageModal.Show("Ошибка", response.errors[0]);
            }
        });
    }

    CategoryName(shopItem : any)
    {
        let filtered = this.shopCategories.filter((item: any) => item.id == shopItem.category_id);

        shopItem.need_save = true;

        if (filtered.length)
            return shopItem.category_name = filtered[0].name;
        else
            return shopItem.category_name = "";
    }

    AddItem()
    {
        let item = {
            id : "",
            category_id : "",
            image : "",
            price : "",
            need_save: true
        };

        this.items.push(item);
    }

    GetProductList()
    {
        var _this = this;

        _this.getModelProducts(_this.userData.id).then(function () {
            _this.UpdateProductList(_this.modelProducts);
        });
    }

    UpdateProductList(productList : any)
    {
        this.items = [];

        for (let product of productList)
        {
            let item = {
                id: product.id,
                image: product.image,
                category_id: product.category_id,
                category_name : this.CategoryName(product),
                name: product.name,
                price: parseFloat(product.price).toFixed(),
            };

            this.items.push(item);
        }
    }

	mounted()
    {
        var _this = this;

        messageModal.Init();

        this.fetchUserData().then(function () {
            _this.GetProductList();
        });

        this.getModelInfo().then(function () {
            if (_this.modelInfo.avatar)
                _this.avatar = "background-image: url('"+ _this.modelInfo.avatar + "')";
        });

        this.getShopCategories();
	}
}
