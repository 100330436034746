import $ from "jquery";

function Init() {
    $('.js-modal-wrap').find('.js-close').on('click', function () {
	    Hide($(this).parents('.js-modal-wrap'));
    });
    
    $('.js-modal-wrap').on('click', function (e) {
	    if(e.target == this )
		    Hide(e.target);
    });
}

function Show(title, text, button = "", buttonLink = "") {
	$('#js-message-modal').find('.js-title').text(title);
	$('#js-message-modal').find('.js-text').text(text);
 
	if (button)
	{
		$('#js-message-modal').find('.js-button').hide();
		$('#js-message-modal').find('.js-button-alt').text(button);
		$('#js-message-modal').find('.js-button-alt').show();
		$('#js-message-modal').find('.js-button-alt').attr('href', buttonLink);
	}
	
    $('#js-message-modal').fadeIn(300);
	$('#js-message-modal .js-modal').css('transform', 'scale(1)');
}

function RegistrationOrLogin(text, text2 = "", icon = "", locker = false)
{
	var modal = $('#js-modal-registration-or-login');
	
	if (locker)
	{
		modal.off('click');
		modal.find('.js-close').off('click');
		modal.find('.js-close').hide();
	}
	
	modal.find('.js-text').text(text);
	modal.find('.js-text-2').text(text2);
	
	if (icon)
		modal.find('.js-icon').attr('src', icon);
	
	modal.fadeIn(300);
	modal.find('.js-modal').css('transform', 'scale(1)');
}

function TopUp(text) {
	var modal = $('#js-top-up-modal');
	
	modal.find('.js-text').text(text);
	
	modal.fadeIn(300);
	modal.find('.js-modal').css('transform', 'scale(1)');
}

function NoFunds() {
	var modal = $('#js-no-funds-modal');
	
	modal.fadeIn(300);
	modal.find('.js-modal').css('transform', 'scale(1)');
}

function Hide(target) {
    $(target).fadeOut(300);
	$(target).find('.js-modal').css('transform', 'scale(0)');
}

export {Init, Show, Hide, RegistrationOrLogin, TopUp, NoFunds}